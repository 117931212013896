//
const zh = {
    lang:'语言',
    about_us: '关于我们',
    business:'商务合作',
    tel:'联系方式:0755-86523347',
    BMT:'羽毛球',
    cycling:'自行车',
    golf:'高尔夫',
    fencing:'剑术',
    tennis:'网球',
    learn_more:'了解详情',
    Copyright:'版权所有',
    please_wait:'敬请期待',
    Splash :'WITI运动，客厅数字运动新时尚',
    navigator_bar: "导航栏",
    about_us_detail:"深圳市维体运动科技有限公司是一家致力于客厅运动智能产品研发与销售的公司。公司坐落于科技之都深圳市南山科技园区，是国家高新技术企业。公司核心创始人来自小天才、育碧、西山居等知名互联网企业。Witi Sports是公司围绕智能运动推出的产品新品类。目前获得来自OPPO、vivo、小天才等企业数千万级天使轮投资。",
    
    business:'商务合作',
    tel:'联系方式:0755-86523347',
    mail:'商务邮箱:business@qimengkeji.net',
    address:'公司地址:广东省深圳市南山区虚拟大学园R2-B栋3楼',

};
export default zh;