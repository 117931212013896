
const en = {
    lang:'Lan',
    about_us:'about us',
    BMT:'badminton',
    cycling:'cycling',
    golf:'golf',
    fencing:'fencing',
    tennis:'tennis',
    learn_more:'learn more',
    Copyright:'Copyright',
    please_wait:'stay wait',
    Splash:'New fashion of digital sports in home',
    navigator_bar:"Nav",
    about_us_detail:"Shenzhen Witi Sports Technology Co., Ltd. is a company dedicated to the research and development and sales of smart products for home exercise. We are the national high-tech enterprise and our core founders come from well-known internet companies such as Imoo, Ubisoft, and Amazing Seasun. Witi Sports is a new product category launched by us around smart sports, and currently has received  millions of angel round investments from companies such as OPPO, vivo and Imoo.",

    business:'business',
    tel:'tel:0755-86523347',
    mail:'mail:business@qimengkeji.net',
    address:'address:3rd Floor, Building R2-B, Virtual University Park, Nanshan District, Shenzhen, Guangdong Province',

};
export default en;