import Vue from "vue";
import VueRouter from "vue-router";

import Home from "@/views/Home.vue";
import Product from "@/views/Product.vue";
import Instructions from "@/views/Instructions.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/product/:name",
    name: "Product",
    component: Product
  },
  {
    path: "/Instructions/:name",
    name: "Instructions",
    component: Instructions
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
