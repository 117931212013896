<template>
  <div class="product">

    <div  class="category-list" >

      <div
        class="category-item-back"
        @click="backhome"
      ></div>
      
     </div>

      <div >
        <div
          class="guide"
          @click="lan_drawer=true"
        >{{$t('lang')}} </div>
        <div
          class="guide_logo"
          @click="backhome"
        ><img
            style="width: 100%;"
            src="@/assets/images/phone/logo.png"
          > </div>
      </div>
      <el-drawer
        :with-header="false"
        :show-close="true"
        :visible.sync="lan_drawer"
        size="35%"
      >
        <div class="phone-category-list">
          <div
            v-for="(tlan, key) in lan_map"
            :key="key"
            class="phone-category-item"
            @click="setLan(key)"
          >
            {{ tlan }}
          </div>
        </div>

      </el-drawer>
      <!-- 翻译文件 -->
    </div>
</template>

<script>
export default {
  name: "Instructions",
  data() {
    return {
      // 中文 zh 英语 en 法语 fr 俄语 ru 葡萄牙语 pt 日语 ja 德语 de 阿拉伯语 ar
      lan_list: [
        "中文",
        "English",
        "Français",
        " Русский язык ",
        "Español",
        "日本語",
        "Deutsch",
        "بالعربية",
      ],
      lan_map: {
        zh: "中文",
        en: "English",
        fr: "Français",
        ru: " Русский язык ",
        pt: "Español",
        ja: "日本語",
        de: "Deutsch",
        ar: "بالعربية",
      },
      showLan: false,
      drawer: false,
      lan_drawer: false,
      windowWidth: 0,
      windowHeight: 0,
      isPhone: false,

      productName: "",
      productImage: "",
      imageData: [],
      categoryDataL: ["BMT", "cycling", "golf", "fencing", "tennis"],
      imageDataConf: {
        BMT: [
          require("@/assets/images/pc/2-1.png"),
          require("@/assets/images/pc/2-2.png"),
          require("@/assets/images/pc/2-3.png"),
          require("@/assets/images/pc/2-4.png"),
        ],
        cycling: [require("@/assets/images/pc/3.png")],
        golf: [
          require("@/assets/images/pc/6-1.png"),
          require("@/assets/images/pc/6-2.png"),
        ],
        fencing: [require("@/assets/images/pc/5.png")],
        tennis: [require("@/assets/images/pc/4.png")],
        about_us: [
          require("@/assets/images/pc/7-1.png"),
          require("@/assets/images/pc/7-2.png"),
        ],
      },
      // categoryDataL: ['BMT', 'cycling', 'golf', 'fencing', 'tennis'],
      phone_imageDataConf: {
        BMT: [
          require("@/assets/images/phone/1-3-1.png"),
          require("@/assets/images/phone/1-3-2.png"),
          require("@/assets/images/phone/1-3-3.png"),
          require("@/assets/images/phone/1-3-4.png"),
        ],
        cycling: [require("@/assets/images/phone/1-4.png")],
        golf: [
          require("@/assets/images/phone/1-7-1.png"),
          require("@/assets/images/phone/1-7-2.png"),
        ],
        fencing: [require("@/assets/images/phone/1-6.png")],
        tennis: [require("@/assets/images/phone/1-5.png")],
        abount_us: [
          require("@/assets/images/phone/1-8-1.png"),
          require("@/assets/images/phone/1-8-2.png"),
        ],
      },
    };
  },
  methods: {
    backhome() {
      // window.location.href = "/"
      this.$router.push("/");
    },
    setLan(lan) {
      console.log("设置语言为", lan);
      this.lan_drawer = false;
      //中文 zh 英语 en 法语 fr 俄语 ru 葡萄牙语 pt 日语 ja 德语 de 阿拉伯语 ar
      this.$i18n.locale = lan;
      // switch(lan){
      //   case 'English':

      //     break;
      //   case '中文':
      //     this.$i18n.locale = 'zh';
      //     break;
      // }
    },
    getProductData(name) {
      // 根据产品名称请求对应数据
      //   "羽毛球", "自行车", "高尔夫", "剑术", "网球"
      let oldname = this.productName;
      let conf = this.imageDataConf[name] || this.imageDataConf["BMT"];
      if (this.isPhone) {
        conf =
          this.phone_imageDataConf[name] || this.phone_imageDataConf["BMT"];
      }
      this.imageData = conf;
      this.productName = name;
      this.productImage = conf[0];
      console.log("getProductData", name, oldname, conf);
      if (oldname != name) {
        this.drawer = false;
        this.$router.afterEach((from, to) => {
          window.scrollTo(0, 0);
        });
      }
    },
  },
  mounted() {
    this.windowHeight = document.documentElement.clientHeight;
    this.windowWidth = document.documentElement.clientWidth;
    if (this.windowWidth <= 820 && this.windowWidth < this.windowHeight) {
      this.isPhone = true;
    } else {
      this.isPhone = false;
    }
    this.getProductData(this.$route.params.name);
    window.onresize = () => {
      this.windowHeight = document.documentElement.clientHeight;
      this.windowWidth = document.documentElement.clientWidth;
      if (this.windowWidth <= 820 && this.windowWidth < this.windowHeight) {
        this.isPhone = true;
      } else {
        this.isPhone = false;
      }
      this.getProductData(this.$route.params.name);
    };
    let navLang = navigator.language || navigator.userLanguage || "zh";
    if (navLang.toLowerCase().indexOf("zh") < 0) {
      this.showLan = true;
    }
  },
  watch: {
    // 监听路由变化，请求对应数据
    "$route.params.name": function () {
      this.getProductData(this.$route.params.name);
    },
  },
};
</script>

<style scoped>
.product {
  text-align: center;
  /* margin-top: 40px; */
}
.product-image {
  width: 70%;
  height: auto;
}
.image-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  /* margin-top: 50px; */
}
.product-picture {
  width: 100%;
  height: auto;
  /* margin-bottom: 30px; */
}

.category-list {
  /* margin-top: 50px; */
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  width: 100vw;
  height: 4.17vw;
  line-height: 4.17vw;
  background-image: url("@/assets/images/pc/title.png");
  background-position: center center;
  background-size: 100%;

  background-color: rgba(3, 3, 3, 0.2);
}
.guide {
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  right: 0.1vw;
  top: 0.2vw;
  z-index: 1000;
  width: 18.5vw;
  height: 6.48vw;
  line-height: 6.48vw;
  justify-content: center;
  color: #ffffff;
  /* padding-right: 10px; */
  font-size: 20px;
  font-weight: 700;
  background-color: rgba(3, 3, 3, 0.6);
}
.phone_lan {
  position: fixed;
  font-size: 20px;
  height: 6.48vw;
  line-height: 6.48vw;
  right: 18.5vw;
  z-index: 1000;
  background-color: rgba(3, 3, 3, 0.6);
  margin-right: 20px;
  color: #fff;
  text-decoration: none;
  font-weight: 900;
}
.guide_logo {
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  left: 1vw;
  top: 1vw;
  z-index: 1000;
  width: 22.87vw;
  height: 7.5vw;
  justify-content: right;
  color: #ffffff;
  /* padding-right: 10px; */
  font-size: 20px;
  background-color: rgba(3, 3, 3, 0.06);
}
.category-item {
  margin-right: 20px;
  font-size: 16px;
  color: #fff;
  text-decoration: none;
  font-weight: 900;
  padding: 0rem 0.5rem;
  /* background-color: blue; */
  border-radius: 10px;
}

.category-item-back {
  position: absolute;
  left: 2vw;
  height: 4.17vw;
  width: 9vw;
  /* background-color: #fff; */
  z-index: 1001;
}
.selected {
  color: #fff;
  background-color: rgba(3, 3, 3, 0.5);
}
.selected2 {
  color: #fff;
  background-color: rgba(3, 3, 3, 0.3);
}
.category-item:hover {
  color: #fff;
  background-color: rgba(3, 3, 3, 0.5);
}

.phone-category-list {
  margin-top: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  /* position: fixed;
  left: 50%; */
  /* transform: translateX(-50%); */
  /* z-index: 1000; */
  /* width: 100vw; */
  /* background-color: rgba(3, 3, 3, 0.2); */
}

.phone-category-item {
  /* margin-right: 20px; */
  font-size: 16px;
  color: #000000;
  text-decoration: none;
  font-weight: 600;
  padding: 0.5rem;
  /* background-color: blue; */
  border-radius: 10px;
  margin-left: 30px;
  margin-right: 30px;
  margin: 10px 30px;
  text-align: left;
}
.image-container {
  width: 100%;
  height: 100vh;
  /* padding-bottom: 66.67%;  */
  /* 对应宽高比，例如：3:2 的宽高比，填写 66.67% */
  /* background-image: url('your-image-url'); */
  /* background-size: 100% 100%; */
  background-size: cover;
  background-position: center center;
}

.details-title {
  position: absolute;
  top: 2.5rem;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.5rem 1.5rem;
  color: rgba(256, 256, 256, 0.8);
  border-radius: 5px;
  cursor: pointer;
  font-size: 40px;
  font-weight: 700;
}
</style>
