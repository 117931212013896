<template>
  <div class="home">

    <!-- WITI品牌大图 -->
    <div
      class="category-list"
      v-if="!isPhone"
    >
      <router-link
        v-for="(category, index) in categoryDataL"
        :key="index"
        :to="{ name: 'Product', params: { name: category } }"
        class="category-item"
      >
        {{ $t(category) }}
      </router-link>
    </div>
    <div
      v-if="showLan && !isPhone"
      class="category-item"
      style="position:fixed;height: 4.17vw;line-height: 4.17vw; right: 110px;z-index: 1000;"
      @click="lan_drawer=true"
    >
      {{$t('lang')}}
    </div>
    <router-link
      v-if="!isPhone"
      :to="{ name: 'Product', params: { name: 'about_us' } }"
      class="category-item"
      style="position:fixed;height: 4.17vw;line-height: 4.17vw; right: 10px;z-index: 1000;"
    >
    {{$t('about_us')}}
      
    </router-link>

    <div v-else>
      <div
        class="guide"
        @click="drawer=true"
      >{{$t('navigator_bar')}}</div>
      <div
        class="guide_logo"
        @click="drawer=true"
      ><img
          style="width: 100%;"
          src="@/assets/images/phone/logo.png"
        > </div>
    </div>
    

    <el-drawer
      :with-header="false"
      :show-close="true"
      :visible.sync="drawer"
      size="45%"
    >
      <div class="phone-category-list">
        <router-link
          v-for="(category, index) in categoryDataL"
          :key="index"
          :to="{ name: 'Product', params: { name: category } }"
          class="phone-category-item"
        >
          {{ $t(category) }}
        </router-link>
        <router-link
          :to="{ name: 'Product', params: { name: 'about_us' } }"
          class="phone-category-item"
        >
          {{$t('about_us')}}
        </router-link>
        <div
          v-if="showLan "
          class="phone-category-item"
          @click="lan_drawer=true"
        >
          {{$t('lang')}}
        </div>
      </div>

    </el-drawer>
<el-drawer
      :with-header="false"
      :show-close="true"
      :visible.sync="lan_drawer"
      size="35%"
    >
      <div class="phone-category-list">
        <div
          v-for="(tlan, index) in lan_list"
          :key="index"
          class="phone-category-item"
          @click="setLan(tlan)"
        >
          {{ tlan }}
        </div>
      </div>

    </el-drawer>
    <el-carousel
      v-if="isPhone"
      @change="BannerChange"
      :autoplay="true"
      :interval="4000"
      arrow="always"
      height="100vh"
    >
      <el-carousel-item
        v-for="(item, k) in phone_banner_src"
        :key="item"
      >
        <div
          class="image-container"
          :style="'background-image: url(\'.' + item + '\');'"
        ></div>
        <!-- <img
          style="width:100%;height:100%; border-radius: 5px;"
          :src="item"
        /> -->
        <img
          src="@/assets/images/phone/1.png"
          class="phone_title"
        />
        <div class="phone_title_text">{{$t('Splash')}}</div>
        <div class="title_bottom">
          <img
            src="@/assets/images/qr.png"
            style="width:13vh;height:13vh"
          />
          <div
            v-if="phone_banner_black[k]"
            class="btns"
          >
            <img
              src="@/assets/images/phone/btn_android_1.png"
              class="btn"
              @click="download_android"
            />
            <img
              src="@/assets/images/phone/btn_ios_1.png"
              class="btn"
              @click="download_ios"
            />
          </div>
          <div
            v-else
            class="btns"
          >
            <img
              src="@/assets/images/phone/btn_android_2.png"
              class="btn"
              @click="download_android"
            />
            <img
              src="@/assets/images/phone/btn_ios_2.png"
              class="btn"
              @click="download_ios"
            />
          </div>

        </div>

      </el-carousel-item>
    </el-carousel>
    <el-carousel
      v-else
      @change="BannerChange"
      style="position: absolute;left: 10vw;top: 20vw;width: 80vw;"
      :autoplay="true"
      :interval="14000"
      type="card"
      arrow="always"
      height="25.2vw"
    >
      <el-carousel-item
        v-for="item in pc_banner_src"
        :key="item"
      >
        <img
          style="width:100%;height:100%; border-radius: 5px;"
          :src="item"
        />
      </el-carousel-item>
    </el-carousel>
    <div
      v-if="!isPhone"
      class="product-item"
    >
      <img
        src="@/assets/images/pc/1-1.png"
        class="animated"
      />
      <div class="title_text">{{$t('Splash')}}</div>
      <div class="title_bottom">
        <!-- <img
          src="@/assets/images/qr.png"
          style="width:18vh;height:18vh"
        /> -->
        <div class="btns" style="flex-direction: row;">
          <img
            src="@/assets/images/pc/btn_android.png"
            class="btn"
            @click="download_android"
          />
          <img
            src="@/assets/images/pc/btn_ios.png"
            class="btn"
            @click="download_ios"
          />
          <img
            src="@/assets/images/pc/btn_win.png"
            class="btn"
            @click="download_win"
          />
        </div>

      </div>
      <!-- <div
        class="title_bottom"
        style="height: 13vh;"
      >
        <div class="btns">
          <img
            src="@/assets/images/pc/btn_android.png"
            class="btn"
            @click="download_android"
          />
        </div>
      </div> -->
    </div>

    <!-- 产品介绍图 -->
    <div
      v-if="!isPhone"
      class="product-list"
    >
      <div
        v-for="(product, index) in productData"
        :key="index"
        class="product-item"
      >
        <!-- <div v-if="isPhone" class="image-container" :style="'background-image: url(\'.' + product.phone_image + '\');'"></div> -->
        <div
          class="image-container"
          :style="'background-image: url(\'.' + product.image + '\');'"
        ></div>
        <!-- <img
          :src="product.image"
          :alt="product.name + '图片'"
          :class="{ 'animated': product.animate }"
          @load="handleImgLoad(index)"
        /> -->
        <div class="details-title"  >{{ $t(product.name_key) }}</div>
        <!-- <button class="details-button" v-show="product.showButton" @click="goToProduct(product)">{{ product.buttonText }}</button> -->
        <button
          class="details-button"
          @click="goToProduct(product)"
        >{{ $t(product.buttonText) }}</button>
      </div>
    </div>
    <div
      v-else
      class="product-list"
    >
      <div
        v-for="(product, index) in productData"
        :key="index"
        class="product-item2"
      >
        <!-- <div v-if="isPhone" class="image-container" :style="'background-image: url(\'.' + product.phone_image + '\');'"></div> -->
        <div
          class="image-container"
          :style="'background-image: url(\'.' + product.phone_image + '\');'"
        ></div>
        <!-- <img
          :src="product.image"
          :alt="product.name + '图片'"
          :class="{ 'animated': product.animate }"
          @load="handleImgLoad(index)"
        /> -->
        <div class="details-title"  >{{ $t(product.name_key) }}</div>
        <!-- <button class="details-button" v-show="product.showButton" @click="goToProduct(product)">{{ product.buttonText }}</button> -->
        <button
          class="details-button btn_phone"
          @click="goToProduct(product)"
        >{{ $t(product.buttonText) }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
// const testJson = require('https://download.witisports.com/help/WitiSports-Windows.json');
export default {
  name: "Home",
  data() {
    return {
      lan_list:['中文','English'],
      showLan:false,
      drawer: false,
      lan_drawer:false,
      windowWidth: 0,
      windowHeight: 0,
      isPhone: false,
      win_url:"https://download.witisports.com/help/witisport-S1.2.1-s15606.zip",
      categoryDataL: ['BMT', 'cycling', 'golf', 'fencing', 'tennis'],
      pc_banner_src: [
        require("@/assets/images/pc/1-1-1.png"),
        require("@/assets/images/pc/1-1-2.png"),
        require("@/assets/images/pc/1-1-3.png"),
        require("@/assets/images/pc/1-1-4.png"),
        require("@/assets/images/pc/1-1-5.png"),
      ],
      phone_banner_src: [
        require("@/assets/images/phone/1-1-1.png"),
        require("@/assets/images/phone/1-1-2.png"),
        require("@/assets/images/phone/1-1-3.png"),
        require("@/assets/images/phone/1-1-4.png"),
        require("@/assets/images/phone/1-1-5.png"),
      ],
      phone_banner_black: [true, true, true, false, false],
      productData: [
        {
          name_key:'BMT',
          image: require("@/assets/images/pc/1-2.png"),
          phone_image: require("@/assets/images/phone/1-2-1.png"),
          animate: false,
          showButton: true,
          buttonText: "learn_more",
        },
        {
          name_key:'cycling',
          image: require("@/assets/images/pc/1-3.png"),
          phone_image: require("@/assets/images/phone/1-2-2.png"),
          animate: false,
          showButton: true,
          buttonText: "learn_more",
        },
        {
          name_key:'golf',
          image: require("@/assets/images/pc/1-4.png"),
          phone_image: require("@/assets/images/phone/1-2-3.png"),
          animate: false,
          showButton: true,
          buttonText: "learn_more",
        },
        {
          name_key:'fencing',
          image: require("@/assets/images/pc/1-5.png"),
          phone_image: require("@/assets/images/phone/1-2-4.png"),
          animate: false,
          showButton: true,
          buttonText: "learn_more",
        },
        {
          name_key:'tennis',
          image: require("@/assets/images/pc/1-6.png"),
          phone_image: require("@/assets/images/phone/1-2-5.png"),
          animate: false,
          showButton: true,
          buttonText: "learn_more",
        },
      ],
    };
  },
  methods: {
    handleImgLoad(index) {
      this.$set(this.productData[index], "animate", true);
    },
    goToProduct(product) {
      this.$router.push({ name: "Product", params: { name: product.name_key } });
    },
    BannerChange(e) {
      console.log("BannerChange", e);
    },
    download_android() {
      window.location.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.qukan.witisports';
      // this.$confirm("敬请期待?", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      //   center: true,
      // })
      //   .then(() => {})
      //   .catch(() => {});
    },
    download_ios() {
      window.location.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.qukan.witisports';
    //   this.$confirm("IOS平台上线中,敬请期待", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //     center: true,
    //   })
    //     .then(() => {})
    //     .catch(() => {});
    },
    download_win(){
      window.location.href = this.win_url;
    },
    setLan(lan){
      console.log("设置语言为",lan)
      this.lan_drawer = false;
      switch(lan){
        case 'English':
          this.$i18n.locale = 'en';
          break;
        case '中文':
          this.$i18n.locale = 'zh';
          break;
      }

    },  
  },
  watch: {
    windowWidth(val) {
      console.log("实时屏幕高度", val);
    },
    isPhone(val) {},
  },
  mounted() {
    this.windowHeight = document.documentElement.clientHeight;
    this.windowWidth = document.documentElement.clientWidth;
    if (this.windowWidth <= 820 && this.windowWidth < this.windowHeight) {
      this.isPhone = true;
    } else {
      this.isPhone = false;
    }
    window.onresize = () => {
      this.windowHeight = document.documentElement.clientHeight;
      this.windowWidth = document.documentElement.clientWidth;
      if (this.windowWidth <= 820 && this.windowWidth < this.windowHeight) {
        this.isPhone = true;
      } else {
        this.isPhone = false;
      }
    };
    let navLang = navigator.language || navigator.userLanguage || 'zh'
    if(navLang.toLowerCase().indexOf("zh") >= 0){
      this.$i18n.locale = 'zh';
    }else{
      this.$i18n.locale = 'en';
      this.showLan = true;
    }
     axios.get('https://download.witisports.com/help/WitiSports-Windows.json').then(res => {
      // console.log("testJson",res)
      this.win_url = res.data.url;
     });
    
    //
  },
};
</script>

<style scoped>
.home {
  text-align: center;
  position: relative;
}
.brand-image {
  width: 100%;
  height: auto;
}
.product-list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  margin-top: -3px;

  /* justify-content: space-between; */
  /* margin-top: 40px; */
}
.product-item {
  width: 100%;
  height: 56.25vw;
  /* margin-bottom: 30px; */
  position: relative;
  margin: 0px;
  padding: 0px;
}
.product-item2 {
  width: 100%;
  height: 100vh;
  position: relative;
  margin: 0px;
  padding: 0px;
}
/* .product-item img {
  width: 100%;
} */

@media (max-width: 768px) {
  .product-item img {
    /* max-height: 50%; */
    width: 100%;
    /* object-fit: cover; */
    /* object-position: 50% 50%; */
    clip-path: inset(0px 25% 0px 25%);
    transform: translateX(-50%);
    position: relative;
    left: 50%;
    /* margin: 0 auto; */
  }
}

/* 应用于设备宽度大于 768px 的情况 */
@media (min-width: 769px) {
  .product-item img {
    width: 100%;
  }
}

.product-item img.animated {
  opacity: 1;
  transform: scale(1);
}
.details-title {
  position: absolute;
  top: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.5rem 1.5rem;
  /* background-color: rgba(256, 256, 256, 0.2); */
  color: rgba(256, 256, 256, 1);
  /* border: none; */
  /* border:  solid #ffffff; */
  border-radius: 5px;
  cursor: pointer;
  /* display: none; */
  font-size: 45px;
  font-weight: 700;
}
.details-button {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  padding: 0rem 1.5rem;
  background-color: rgba(256, 256, 256, 0.1);
  width: 25vw;
  height: 4vw;
  line-height: 4vw;
  color: #ffffff;
  /* border: none; */
  border: solid #ffffff 1.5px;
  border-radius: 5px;
  /* font-size: 30px; */
  font-size: 2vw;
  cursor: pointer;
  /* display: none; */
}
.btn_phone {
  width: 44vw;
  height: 8vw;
}

.title_bottom {
  position: absolute;
  bottom: 5px;
  left: 50%;
  height: 20vh;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.title_bottom .btns {
  display: flex;
  flex-direction: column;
  margin-left: 2vw;
  justify-content: center;
}
.title_bottom .btns .btn {
  height: 6vh;
  width: 22.5vh;
  margin: 0.7vw;
}

.product-item:hover .details-button {
  display: block;
}
/* .product-item:hover .details-title {
  display: block;
} */

.category-list {
  /* margin-top: 50px; */
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  width: 100vw;
  height: 4.17vw;
  line-height: 4.17vw;
  background-image: url("@/assets/images/pc/title.png");
  background-position: center center;
  background-size: 100%;

  background-color: rgba(3, 3, 3, 0.2);
}
.guide {
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  right: 0.1vw;
  top: 0.2vw;
  z-index: 1000;
  width: 18.5vw;
  height: 6.48vw;
  line-height: 6.48vw;
  justify-content: center;
  color: #ffffff;
  /* padding-right: 10px; */
  font-size: 20px;
  font-weight: 700;
  background-color: rgba(3, 3, 3, 0.6);
}
.phone_lan {
  position:fixed;
  font-size: 20px;
  height: 6.48vw;
  line-height: 6.48vw; 
  right: 18.5vw;
  z-index: 1000; 
  background-color: rgba(3, 3, 3, 0.6);
  margin-right: 20px;
  color: #fff;
  text-decoration: none;
  font-weight: 900;
}
.guide_logo {
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  left: 1vw;
  top: 1vw;
  z-index: 1000;
  width: 22.87vw;
  height: 7.5vw;
  justify-content: right;
  color: #ffffff;
  /* padding-right: 10px; */
  font-size: 20px;
  background-color: rgba(3, 3, 3, 0.06);
}
.category-item {
  margin-right: 20px;
  font-size: 16px;
  color: #fff;
  text-decoration: none;
  font-weight: 900;
  padding: 0rem 0.5rem;
  /* background-color: blue; */
  border-radius: 10px;
}
.category-item:hover {
  color: #fff;
  background-color: rgba(3, 3, 3, 0.5);
}
.el-carousel__mask {
  /* background-color: rgba(256, 256, 256, 0); */
  display: none;
}

.phone-category-list {
  margin-top: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  /* position: fixed;
  left: 50%; */
  /* transform: translateX(-50%); */
  /* z-index: 1000; */
  /* width: 100vw; */
  /* background-color: rgba(3, 3, 3, 0.2); */
}

.phone-category-item {
  /* margin-right: 20px; */
  font-size: 16px;
  color: #000000;
  text-decoration: none;
  font-weight: 600;
  padding: 0.5rem;
  /* background-color: blue; */
  border-radius: 10px;
  margin: 10px 30px;
  text-align: left;
}

.image-container {
  width: 100%;
  height: 100%;
  /* padding-bottom: 66.67%;  */
  /* 对应宽高比，例如：3:2 的宽高比，填写 66.67% */
  /* background-image: url('your-image-url'); */
  /* background-size: 100% 100%; */
  background-size: cover;
  background-position: center center;
}
.phone_title {
  width: 85vw;
  /* height: 23.7vw; */
  height: 16.296vw;
  top: 22vw;
  position: absolute;
  left: 7.5vw;
}
.phone_title_text{
  width: 85vw;
  height: 7.4vw;
  font-size: 5vw;
  line-height: 7.4vw;
  top: 38.3vw;
  position: absolute;
  left: 7.5vw;
  color: white;
}
.title_text{
  width: 70vw;
  height: 5vw;
  font-size: 3vw;
  line-height: 5vw;
  top: 13.5vw;
  position: absolute;
  left: 12.5vw;
  color: white;
}
@media (min-aspect-ratio: 9/16) {
  /* 屏幕宽高比变宽时，裁剪上下部分 */
  /* .image-container {
            background-position: center center;
        } */
}

@media (max-aspect-ratio: 9/16) {
  /* 屏幕宽高比变长时，裁剪左右部分 */
  /* .image-container {
            background-position: center center;
        } */
}
</style>
