<template>
  <div class="product">
    <!-- 产品分类大图 -->
    <!-- <img :src="productImage" :alt="productName + '图片'" class="product-image"> -->

    <!-- 5张大图 -->
    <div
      class="category-list"
      v-if="!isPhone"
    >
      <router-link
        v-for="(category, index) in categoryDataL"
        :key="index"
        :class="{'category-item':true, 'selected': productName == category}"
        :to="{ name: 'Product', params: { name: category } }"
        class="category-item"
      >
        {{ $t(category) }}
      </router-link>
    </div>
    <div v-if="!isPhone" class="category-item-back" @click="backhome"></div>
 <div
      v-if="showLan && !isPhone"
      class="category-item"
      style="position:fixed;height: 4.17vw;line-height: 4.17vw; right: 110px;z-index: 1000;"
      @click="lan_drawer=true"
    >
      {{$t('lang')}}
    </div>
    <router-link
      v-if="!isPhone"
      :to="{ name: 'Product', params: { name: 'about_us' } }"
      class="category-item"
      style="position:fixed;height: 4.17vw;line-height: 4.17vw; right: 10px;z-index: 1000;"
    >
      {{$t('about_us')}}
    </router-link>
     <div v-else>
        <div   class="guide" @click="drawer=true">{{$t('navigator_bar')}} </div>
    <div  class="guide_logo" @click="backhome"><img  style="width: 100%;" src="@/assets/images/phone/logo.png"> </div>
    </div>
 <div
      v-if="showLan && isPhone"
      class=" phone_lan"
      @click="lan_drawer=true"
    >
      {{$t('lang')}}
    </div>
    <el-drawer
      :with-header="false"
      :show-close="true"
      :visible.sync="drawer"
      size="45%"
    >
      <div class="phone-category-list">
        <router-link
          v-for="(category, index) in categoryDataL"
          :key="index"
          :to="{ name: 'Product', params: { name: category } }"
          
          :class="{'phone-category-item':true, 'selected2': productName == category}"
        >
          {{ $t(category) }}
        </router-link>
        <router-link
          :to="{ name: 'Product', params: { name: 'about_us' } }"
          :class="{'phone-category-item':true, 'selected2': productName == 'about_us'}"
        >
          {{$t('about_us')}}
        </router-link>
      </div>

    </el-drawer>
    <el-drawer
      :with-header="false"
      :show-close="true"
      :visible.sync="lan_drawer"
      size="35%"
    >
      <div class="phone-category-list">
        <div
          v-for="(tlan, index) in lan_list"
          :key="index"
          class="phone-category-item"
          @click="setLan(tlan)"
        >
          {{ tlan }}
        </div>
      </div>

    </el-drawer>
    <div v-if="productName == 'about_us'">
       <div
        class="image-list"
      >
      
    <div class="details-title"  >{{ $t(productName) }}</div>
      <div v-if="isPhone">
          <img src="@/assets/images/phone/1-8-1.png" class="product-picture" />
           <div class="aboutus-text-phone">
      <div class="title">{{ $t(productName) }}</div>
      <div class="detail">{{$t('about_us_detail')}}</div>
    </div>
    <div class="business-text-phone">
      <div class="title">{{ $t('business') }}</div>
      <div class="detail">{{$t('tel')}}</div>
      <div class="detail">{{$t('mail')}}</div>
      <div class="detail">{{$t('address')}}</div>
    </div>
    <img src="@/assets/images/phone/1-8-3.png" class="product-picture"/>
      </div>
      <div v-else>
        <img src="@/assets/images/pc/7-1.png" class="product-picture">
        <div class="aboutus-text">
          <div class="title">{{ $t(productName) }}</div>
          <div class="detail">{{$t('about_us_detail')}}</div>
        </div>
        </img>
        <img src="@/assets/images/pc/7-3-3.png" class="product-picture">
        <div class="business-text">
          <div class="title">{{ $t('business') }}</div>
          <div class="detail">{{$t('tel')}}</div>
          <div class="detail">{{$t('mail')}}</div>
          <div class="detail">{{$t('address')}}</div>
        </div>
        </img>
      </div>
    
      <!-- <img
        v-for="(item, index) in imageData"
        :key="index"
        :src="item"
        :alt="'图片' + index"
        class="product-picture"
      > -->
    </div>
    </div>
    <div v-else>
      <div
      v-show="imageData.length > 0 && isPhone "
      class="image-list"
    >
    <div class="details-title"  >{{ $t(productName) }}</div>
      <div
        v-for="(item, index) in imageData"
        :key="index"
        :src="item"
        class="image-container"
        :style="'background-image: url(\'' + item + '\');'"
      ></div>
    </div>
     <div
      v-show="imageData.length > 0 && !isPhone "
      class="image-list"
    >
    <div class="details-title"  >{{ $t(productName) }}</div>
      <img
        v-for="(item, index) in imageData"
        :key="index"
        :src="item"
        :alt="'图片' + index"
        class="product-picture"
      >
    </div>

    </div>
    
    <!-- 产品分类列表 -->
    <!-- <div class="category-list">
      <router-link v-for="(category, index) in categoryData" :key="index" :to="{ name: 'Product', params: { name: category } }" class="category-item">
        {{ category }}
      </router-link>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "Product",
  data() {
    return {
      lan_list:['中文','English'],
      showLan:false,
      drawer: false,
	    lan_drawer:false,
      windowWidth: 0,
      windowHeight: 0,
      isPhone: false,

      productName: "",
      productImage: "",
      imageData: [],
      categoryDataL: ['BMT', 'cycling', 'golf', 'fencing', 'tennis'],
      imageDataConf: {
        BMT: [
          require("@/assets/images/pc/2-1.png"),
          require("@/assets/images/pc/2-2.png"),
          require("@/assets/images/pc/2-3.png"),
          require("@/assets/images/pc/2-4.png"),
        ],
        cycling: [
          require("@/assets/images/pc/3.png"),
        ],
        golf: [
          require("@/assets/images/pc/6-1.png"),
          require("@/assets/images/pc/6-2.png"),
        ],
        fencing: [
          require("@/assets/images/pc/5.png"),
        ],
        tennis: [
          require("@/assets/images/pc/4.png"),
        ],
        about_us: [
          require("@/assets/images/pc/7-1.png"),
          require("@/assets/images/pc/7-2.png"),
        ],
      },
      // categoryDataL: ['BMT', 'cycling', 'golf', 'fencing', 'tennis'],
      phone_imageDataConf: {
        BMT: [
          require("@/assets/images/phone/1-3-1.png"),
          require("@/assets/images/phone/1-3-2.png"),
          require("@/assets/images/phone/1-3-3.png"),
          require("@/assets/images/phone/1-3-4.png"),
        ],
        cycling: [
          require("@/assets/images/phone/1-4.png"),
        ],
        golf: [
          require("@/assets/images/phone/1-7-1.png"),
          require("@/assets/images/phone/1-7-2.png"),
        ],
        fencing: [
          require("@/assets/images/phone/1-6.png"),
        ],
        tennis: [
          require("@/assets/images/phone/1-5.png"),
        ],
        about_us: [
          require("@/assets/images/phone/1-8-1.png"),
          require("@/assets/images/phone/1-8-2.png"),
        ],
      },
    };
  },
  methods: {
    backhome(){
      // window.location.href = "/"
      this.$router.push('/')
    },
    setLan(lan){
      console.log("设置语言为",lan)
      this.lan_drawer = false;
      switch(lan){
        case 'English':
          this.$i18n.locale = 'en';
          break;
        case '中文':
          this.$i18n.locale = 'zh';
          break;
      }
    },
    getProductData(name) {
      // 根据产品名称请求对应数据
      //   "羽毛球", "自行车", "高尔夫", "剑术", "网球"
      let oldname = this.productName;
      let conf = this.imageDataConf[name] || this.imageDataConf["BMT"];
      if (this.isPhone) {
        conf =
          this.phone_imageDataConf[name] || this.phone_imageDataConf["BMT"];
      }
      this.imageData = conf;
      this.productName = name;
      this.productImage = conf[0];
      console.log("getProductData",name, oldname,conf);
      if (oldname != name) {
        this.drawer = false
        this.$router.afterEach((from, to) => {
          window.scrollTo(0, 0);
        });
      }
    },
  },
  mounted() {
    this.windowHeight = document.documentElement.clientHeight;
    this.windowWidth = document.documentElement.clientWidth;
    if (this.windowWidth <= 820 && this.windowWidth < this.windowHeight) {
      this.isPhone = true;
    } else {
      this.isPhone = false;
    }
    this.getProductData(this.$route.params.name);
    window.onresize = () => {
      this.windowHeight = document.documentElement.clientHeight;
      this.windowWidth = document.documentElement.clientWidth;
      if (this.windowWidth <= 820 && this.windowWidth < this.windowHeight) {
        this.isPhone = true;
      } else {
        this.isPhone = false;
      }
      this.getProductData(this.$route.params.name);
    };
    let navLang = navigator.language || navigator.userLanguage || 'zh'
    if(navLang.toLowerCase().indexOf("zh") < 0){
      this.showLan = true;
    }
  },
  watch: {
    // 监听路由变化，请求对应数据
    "$route.params.name": function () {
      this.getProductData(this.$route.params.name);
    },
  },
};
</script>

<style scoped>
.product {
  text-align: center;
  /* margin-top: 40px; */
}
.product-image {
  width: 70%;
  height: auto;
}
.image-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  /* margin-top: 50px; */
}
.product-picture {
  width: 100%;
  height: auto;
  /* margin-bottom: 30px; */
}

.category-list {
  /* margin-top: 50px; */
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  width: 100vw;
  height: 4.17vw;
  line-height: 4.17vw;
  background-image: url('@/assets/images/pc/title.png');
  background-position: center center;
  background-size: 100%;
  
  background-color: rgba(3, 3, 3, 0.2);
}
.guide {
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  right: 0.1vw;
  top: 0.2vw;
  z-index: 1000;
  width: 18.5vw;
  height: 6.48vw;
  line-height: 6.48vw;
  justify-content: center;
  color: #ffffff;
  /* padding-right: 10px; */
  font-size: 20px;
  font-weight: 700;
  background-color: rgba(3, 3, 3, 0.6);
}
.phone_lan {
  position:fixed;
  font-size: 20px;
  height: 6.48vw;
  line-height: 6.48vw; 
  right: 18.5vw;
  z-index: 1000; 
  background-color: rgba(3, 3, 3, 0.6);
  margin-right: 20px;
  color: #fff;
  text-decoration: none;
  font-weight: 900;
}
.guide_logo {
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  left: 1vw;
  top: 1vw;
  z-index: 1000;
  width: 22.87vw;
  height: 7.5vw;
  justify-content: right;
  color: #ffffff;
  /* padding-right: 10px; */
  font-size: 20px;
  background-color: rgba(3, 3, 3, 0.06);
}
.category-item {
  margin-right: 20px;
  font-size: 16px;
  color: #fff;
  text-decoration: none;
  font-weight: 900;
  padding: 0rem 0.5rem;
  /* background-color: blue; */
  border-radius: 10px;
}

.category-item-back {
  
  position: absolute;
  left: 2vw;
  height: 4.17vw;
  width: 9vw;
  /* background-color: #fff; */
  z-index: 1001;
}
.selected {
  color: #fff;
  background-color: rgba(3, 3, 3, 0.5);
}
.selected2 {
  color: #fff;
  background-color: rgba(3, 3, 3, 0.3);
}
.category-item:hover {
  color: #fff;
  background-color: rgba(3, 3, 3, 0.5);
}

.phone-category-list {
  margin-top: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  /* position: fixed;
  left: 50%; */
  /* transform: translateX(-50%); */
  /* z-index: 1000; */
  /* width: 100vw; */
  /* background-color: rgba(3, 3, 3, 0.2); */
}

.phone-category-item {
  /* margin-right: 20px; */
  font-size: 16px;
  color: #000000;
  text-decoration: none;
  font-weight: 600;
  padding:  0.5rem;
  /* background-color: blue; */
  border-radius: 10px;
  margin-left: 30px;
  margin-right: 30px;
  margin:10px 30px;
  text-align: left;
}
.image-container {
  width: 100%;
  height: 100vh;
  /* padding-bottom: 66.67%;  */
  /* 对应宽高比，例如：3:2 的宽高比，填写 66.67% */
  /* background-image: url('your-image-url'); */
  /* background-size: 100% 100%; */
  background-size: cover;
  background-position: center center;
}

.details-title {
  position: absolute;
  top: 2.5rem;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.5rem 1.5rem;
  color: rgba(256, 256, 256, 0.8);
  border-radius: 5px;
  cursor: pointer;
  font-size: 30px;
  font-weight: 700;
}
.aboutus-text {
  position: absolute;
  top: 56.25vw;
  left: 37.5vw;
  width: 61vw;
  display: flex;
  flex-direction: column;
}
.aboutus-text .title{
  padding:  1vw 3.8vw;
  font-size: 2.5vw;
  font-weight: 500;
  color: #5b5a54;
  text-align: left;
}
.aboutus-text .detail{
  padding:  0.3vw 3vw 0.5vw 3.8vw;
  font-size: 1.2vw;
  line-height: 2.4vw;
  color: #969796;
  text-align: left;
}
.business-text{
  width: 50vw;
  position: relative;
  top: -30vw;
  left: 0vw;
  display: flex;
  flex-direction: column;
}
.business-text .title{
   padding:  1vw 3.8vw;
  font-size: 2.5vw;
  font-weight: 500;
  color: #5b5a54;
  text-align: left;
}
.business-text .detail{
  padding:  1vw 8vw 1vw 3.8vw;
  font-size: 1.4vw;
  line-height: 2.4vw;
  color: #969796;
  text-align: left;
}


.aboutus-text-phone {
  /* position: absolute;
  top: 56.25vw;
  left: 37.5vw; */
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding-top: 5vw;
  padding-bottom: 10vw;
}
.aboutus-text-phone .title{
  padding:  1vw 3.8vw;
  font-size: 20px;
  font-weight: 500;
  color: #5b5a54;
  text-align: left;
}
.aboutus-text-phone .detail{
  padding:  2vw 8vw 0.5vw 3.8vw;
  font-size: 12px;
  line-height:25px;
  color: #969796;
  text-align: left;
  
}
.business-text-phone{
  width: 100vw;
  /* position: relative;
  top: -30vw;
  left: 0vw; */
  display: flex;
  flex-direction: column;
  background-color: #eeeeee;
  padding-top: 5vw;
  padding-bottom: 10vw;
}
.business-text-phone .title{
   padding:  1vw 3.8vw;
  font-size: 20px;
  font-weight: 500;
  color: #5b5a54;
  text-align: left;
}
.business-text-phone .detail{
  padding:  3vw 8vw 1vw 3.8vw;
  font-size: 13px;
  line-height:25px;
  color: #969796;
  text-align: left;
}

</style>
